import React from "react"
import Navbar from "../components/Navbar"
import Sidebar from '../components/Sidebar'
import Spacer from "../components/Spacer"
import Footer from "../components/Footer"
import Header from "../components/TitleElement.js"
import CenterText from '../components/CenterText.js'
import { Helmet } from 'react-helmet' 


export const ImprintTemplate = ({
    navbar,
    sidebar,
    footer1,
    footer2,
    imprint
    }) => (
        <div>
            <Helmet>
                <meta name="robots" content="noindex" charSet="utf-8"/>
                <title>Impressum</title>
                <html lang="de"/>
            </Helmet>

            <Navbar data={navbar} topbar={sidebar}/>
            <Sidebar data={sidebar}/>
            <Spacer/>
            <Header>{imprint.title}</Header>
            <Spacer/>
            <CenterText>
                <div dangerouslySetInnerHTML={{__html:imprint.text}}/>
            </CenterText>
            <Spacer height="100" unit="px"/>
            <Footer data={footer1} data2={footer2} id="contact"/>
        </div>
)
