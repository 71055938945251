import React from "react"
import styled from "styled-components"

const Container = styled.div`
width:90%;
max-width:700px;
margin:auto;

font-size:1.25em;
font-family:var(--textfont);
color:var(--grey);


& * {
    margin:2px;
}
& h3 {
    font-family:var(--headerfont);
    font-size:1.25em;
    margin-top:20px;
}

`

export default function CenterText(props) {
    return(
        <Container>
           {props.children}
        </Container>
    )
}