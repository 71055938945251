import React from "react"
import { graphql } from 'gatsby'
import { ImprintTemplate } from '../templates/imprintTemplate.js'

/*

*/
export default function Imprint({data}) {
    const navbar = data.allDataJson.nodes[0].index.navbar
    const sidebar = data.allDataJson.nodes[0].index.general
    const footer1 = data.allDataJson.nodes[0].index.general
    const footer2 = data.allDataJson.nodes[0].index.footer
    const imprint = data.allDataJson.nodes[0].imprint
    return (
        <ImprintTemplate
        navbar={navbar}
        sidebar={sidebar}
        footer1={footer1}
        footer2={footer2}
        imprint = {imprint}
        />

    )
}

export const query = graphql`
{
    allDataJson {
        nodes {
        index {
            navbar {
                title
                navigationButtons {
                title
                link
                }
            }
            general {
                title
                opening {
                details{
                    text
                }
                times {
                    days
                    time
                }
                title
                }
                phone {
                detail
                number
                title
                }
                email {
                    title
                    adress
                }
                adress {
                title
                street
                zipcode
                }
            }
            footer {
                slogan
                copyright
                endorsement
            }
        }
        imprint {
                title
                text
            }
        }
    }
}

`